import sanityClint from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClint({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: "production",
  apiVersion: "2021-03-25",
  useCdn: true,
  token: process.env.REACT_APP_SANITY_TOKEN,
});

//this gonna to be usful for our imgs
const builder = imageUrlBuilder(client);
// this could usually use when ever you treat with sanity projects
export const urlFor = (source) => builder.image(source);
